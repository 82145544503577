import {
  BREAKPOINT_SM,
  checkElementVisibleByClass,
  isResponsiveScreen,
} from '../../abstracts/responsive';

const contentAds = () => {
  const zones = bergfex?.promotionZones || {
    skyscraper: 2,
    promotion: 3,
    content: 46,
    contentFillup: 54,
    leaderboard: 55,
    halfpage: 56,
    mobileBanner31: 57,
    webcamListCard: 60,
  };

  // extend this for other promotion zones when needed (adblocker fallback)
  const promotionZoneTypes = {
    [zones.skyscraper]: 'skyscraper',
    [zones.halfpage]: 'halfpage',
    [zones.content]: 'sm',
    [zones.contentFillup]: 'sm',
    [zones.leaderboard]: 'leaderboard',
    [zones.mobileBanner31]: 'mobile-banner-31',
    [zones.webcamListCard]: 'card-webcamlist',
  };

  const baseUrl = '//partner.bergfex.at/delivery/jsonp/';

  let mocaLoaded = false;

  const debugAdFallback = (className, index = 0) => {
    const container = document.getElementsByClassName(className)[index];
    console.log('DEBUG: checking container: ', container);
    console.log('DEBUG: is container empty?', isContainerEmpty(container));
  };

  const initPromoboxFallback = (container, handleFallback = handleNoAdVisible) => {
    const timeout = setTimeout(() => {
      if (!isElementVisible(container) || isContainerEmpty(container)) {
        handleFallback(container);
      }
    }, 3000);

    if (!isElementVisible(container)) {
      handleFallback(container);
      clearTimeout(timeout);
      return false;
    }

    return true;
  };

  const initContentAds = () => {
    [...document.getElementsByClassName('partner')].forEach((container) => {
      if (
        container.className.includes('noinit') ||
        !checkElementVisibleByClass(container.className)
      ) {
        return;
      }

      initContentAd(container);
    });
  };

  const initContentAd = (container, handleFallback = handleNoAdVisible) => {
    if (!initPromoboxFallback(container, handleFallback)) {
      return;
    }

    // check if mobile placing is available
    if (container.classList.contains('responsive-hidden') && isResponsiveScreen()) {
      return;
    }

    let zoneid = parseInt(container.dataset.zoneid);

    if (typeof SDG !== 'undefined') {
      SDG.Publisher.getIABVendorConsent(137, function (vendorConsent) {
        if (vendorConsent === false) {
          switch (zoneid) {
            // fallbacks for internal only ad zone
            case zones.content:
              zoneid = 47;
              break;
            case zones.skyscraper:
              zoneid = 48;
              break;
          }
        }
      });
    }

    if (
      zoneid === zones.skyscraper &&
      (!document.getElementById('sidebar') || getAvailableWidth() < 100)
    ) {
      return;
    }

    fetchJsonAd(container, zoneid, getUrlSearchParams(container, zoneid), getBaseUrl(container));
  };

  const getBaseUrl = (container) => {
    if (!container.dataset.banner) {
      return baseUrl;
    }

    return baseUrl + 'direct/';
  };

  const getAvailableWidth = () =>
    window.innerWidth - document.getElementsByTagName('main')[0].offsetWidth;

  const getUrlSearchParams = (container, zoneid) => {
    const url = new URLSearchParams();

    if (container.dataset.banner) {
      url.set('bannerid', container.dataset.banner);
      url.set('zoneid', zoneid);
      url.set('json', '1');
    } else {
      let currentLocation = window.location.href.split('dev');
      url.set('zoneid', zoneid);
      url.set('source', container.dataset.geo);
      url.set('tags', container.dataset.tags);
      url.set(
        'loc',
        currentLocation.length > 1
          ? currentLocation[0] + 'www' + currentLocation[1]
          : currentLocation[0],
      );
      url.set('ts', new Date().getTime().toString());
      url.set('isAjax', '1');

      if (zoneid === zones.skyscraper) {
        url.set('freewidth', '' + getAvailableWidth());
      } else if (zoneid === zones.content) {
        url.set('mobile', isResponsiveScreen(BREAKPOINT_SM) ? '1' : '0');
      }
    }

    return url;
  };

  const isElementVisible = (element) =>
    element.style.display !== 'none' &&
    (element.offsetWidth || element.offsetHeight || element.getClientRects().length);

  const isContainerEmpty = (container) => {
    if (container.classList.contains('promotionzone')) {
      return false;
    }

    if (
      container.style.display !== 'none' &&
      (container.offsetHeight < 5 || container.innerHTML.trim() === '')
    ) {
      return true;
    }

    const skipTags = ['SCRIPT', 'STYLE', 'svg', 'LINK'];
    for (const child of container.children) {
      if (skipTags.includes(child.tagName)) {
        continue;
      }

      if (child.style.display !== 'none' && child.offsetHeight > 5) {
        return false;
      }

      if (child.tagName === 'IFRAME') {
        return false;
      }

      if (child.innerHTML.trim() === '') {
        return true;
      }

      if (!isContainerEmpty(child)) {
        return false;
      }
    }

    return true;
  };

  const handleNoAdVisible = (container) => {
    const zoneid = parseInt(container.dataset.zoneid);

    container.className = container.className.replaceAll('adzone', 'promotionzone');
    if (promotionZoneTypes[zoneid]) {
      container.style.display = '';
      getPromotionZone(container, promotionZoneTypes[zoneid], 1);
    }
  };

  const fetchJsonAd = (container, zoneid, url, base) => {
    const scriptId = `adzone_${Date.now()}_${Math.ceil(Math.random() * 100000)}`;
    return new Promise(() => {
      url = base + '?' + url.toString();
      url += "&jsoncallback=bergfex.contentAds.displayAd('" + scriptId + "')";

      const jsonpScript = document.createElement('script');
      jsonpScript.async = true;
      jsonpScript.setAttribute('src', url);
      jsonpScript.setAttribute('id', scriptId);

      document.getElementsByTagName('head')[0].appendChild(jsonpScript);
      container.setAttribute('data-scriptid', scriptId);
      container.classList.add('isLoading');
      jsonpScript.onerror = () => cleanUpAfterFetching(scriptId);
    }).catch(() => cleanUpAfterFetching(scriptId));
  };

  const cleanUpAfterFetching = (scriptId) => {
    document.getElementById(scriptId)?.remove();
    document.querySelector("[data-scriptid='" + scriptId + "']")?.removeAttribute('data-scriptid');
  };

  /**
   * callback function of fetchJsonAd with fetching data with jsonp
   * @param scriptId
   * @returns {(function(*): (undefined|void))|*}
   */
  const displayAd = (scriptId) => {
    return function (banner) {
      let container = document.querySelector("[data-scriptid='" + scriptId + "']");
      cleanUpAfterFetching(scriptId);

      if (!receivedBanner(banner)) {
        if (parseInt(container.dataset.zoneid) !== zones.content) {
          return;
        }

        return getPromotionZone(container);
      }

      if (typeof googletag != 'undefined') {
        googletag.cmd.push(function () {
          googletag.destroySlots();
        });
      }

      if (!isBannerMocaFirst(banner) || mocaLoaded) {
        return showBannerHTML(container, banner);
      }

      const jsonpScript = document.createElement('script');
      jsonpScript.setAttribute('src', 'https://mocafirst.at/moca.php?id=1000024');
      jsonpScript.async = true;
      document.getElementsByTagName('head')[0].appendChild(jsonpScript);

      jsonpScript.onload = () => {
        mocaLoaded = true;
        showBannerHTML(container, banner);
        jsonpScript.remove();
      };
    };
  };

  const showBannerHTML = (container, banner) => {
    if (isSitebar(banner)) {
      container.classList.add('sitebar');
      container.parentElement.classList.add('sitebar');
    }

    const zoneId = container.dataset.zoneid;
    container.classList.remove('isLoading');
    hideZoneSpacer(container);
    container.classList.add('loaded');
    container.style.display = 'block';

    if (forceIframe(banner)) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', 'https://partner.bergfex.at/cache/' + banner.bannerid + '.html');
      iframe.style.overflow = 'hidden';
      iframe.style.margin = 'auto';
      iframe.style.height = '100%';
      iframe.style.width = '100%';
      iframe.style.minWidth = banner.width + 'px';
      iframe.style.minHeight = banner.height + 'px';
      container.appendChild(iframe);
    } else {
      container.innerHTML = banner.html;
      stripAndExecuteScript(container);

      const iframe = container.querySelectorAll(':scope iframe');

      if (iframe && iframe.length > 0) {
        iframe[0].style.aspectRatio = banner.width + ' / ' + banner.height;
      }
    }

    if (parseInt(zoneId) !== zones.content) {
      //if not content box ad zone
      return;
    }

    setTimeout(function () {
      if (container.getBoundingClientRect().height > 10 && container.childNodes.length > 0) {
        return; //banner is displayed
      }
      // no banner was displayed, get fallback for content zone
      getPromotionZone(container, 'sm', 1);
    }, 3000);
  };

  const stripAndExecuteScript = (elm) => {
    Array.from(elm.querySelectorAll('script')).forEach((oldScriptEl) => {
      const newScriptEl = document.createElement('script');

      Array.from(oldScriptEl.attributes).forEach((attr) => {
        newScriptEl.setAttribute(attr.name, attr.value);
      });

      const scriptText = document.createTextNode(oldScriptEl.innerHTML);
      newScriptEl.appendChild(scriptText);
      oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
  };

  const hideZoneSpacer = (container) => {
    if (
      parseInt(container?.previousElementSibling?.dataset?.zoneid) ===
      parseInt(container?.dataset?.zoneid)
    ) {
      container.previousElementSibling.remove();
    }
  };

  /**
   * force iframe usage for non-async adtags
   * @param banner
   * @returns {boolean}
   */
  const forceIframe = (banner) =>
    banner.name.toLowerCase().indexOf('iframe') !== -1 && !isBannerMocaFirst(banner);

  const isSitebar = (banner) =>
    banner.name.toLowerCase().indexOf('sitebar') !== -1 || banner.html.indexOf('sitebar') !== -1;

  const isBannerMocaFirst = (banner) => banner.html.indexOf('mocafirst') !== -1;

  const receivedBanner = (banner) =>
    typeof banner.html === 'string' && banner.html.length > 0 && typeof banner.name !== undefined;

  const getPromotionZone = (container, type, withFallback = 0) => {
    if (!type) {
      type = promotionZoneTypes[container?.dataset?.zoneid ?? zones.content];
    }

    fetchPromotionZone(type, withFallback).then((data) => {
      container.classList.remove('isLoading');
      hideZoneSpacer(container);

      if (!data?.promotion) {
        container.style.display = 'none';
      }

      container.innerHTML = data?.promotion ?? '';
    });
  };

  const fetchPromotionZone = async (type = 'sm', withFallback = 0) => {
    let response = await fetch(
      '/ajax/promotion_zone/?type=' + type + '&withFallback=' + withFallback,
      {
        cache: 'no-store',
      },
    );
    try {
      return await response.json();
    } catch (e) {
      return '';
    }
  };

  document.addEventListener('DOMContentLoaded', () => {
    initContentAds();
  });

  bergfex = bergfex || {};
  bergfex.contentAds = {
    displayAd,
    isElementVisible,
    getPromotionZone,
    initContentAd,
    debugAdFallback,
    hideZoneSpacer,
  };
};

export default contentAds;
